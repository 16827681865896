import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { MT5MobileQuery } from "../../prismic/staticQueries";
import { connect, useSelector } from "react-redux";
import BANNER from "../../images/siteImages/mt4Mobile/MT4_MOBILE_BANNER.jpg";
import MT4_MOBILE_DOWNLOAD from "../../images/siteImages/mt4Mobile/MT4_MOBILE_DOWNLOAD.png";

import Layout from "../../layout";
import { BreadCrumbBanner, Subscription } from "../../components/common";

const OurMission = () => {
  const language = useSelector((state) => state.language);
  const { main_buttons, download_box, content_body, content_heading } =
    MT5MobileQuery(language);
  return (
    <>
      <Layout>
        <BreadCrumbBanner
          showButtons={false}
          heading={""}
          banner_image={BANNER}
          buttons={main_buttons}
        />
        {/* <QuickStats heading={stats_heading} items={stats_items} /> */}
        <section className="job-contents p-top-20 p-bottom-65 mb-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h3 className="text-center pt-5">{content_heading.text}</h3>
                <PrismicRichText field={content_body.richText} />
                <img
                  src={MT4_MOBILE_DOWNLOAD}
                  style={{ padding: "2rem 0rem" }}
                  alt="MT5"
                />
                {/* <div className="hr-divider2"></div> */}
              </div>
              <div className="col-lg-12 row">
                {download_box.map((item, itemInd) => (
                  <div
                    className="col-sm-6 text-center"
                    key={"sdffhgd" + itemInd}
                  >
                    <a
                      href={item.download_link.text}
                      className="btn  rounded-0 btn-icon icon-right"
                      style={{
                        backgroundColor: "#1175bb",
                        color: "#fff",
                        width: "25rem",
                      }}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.download_title.text}{" "}
                      <span className="la la-download"></span>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <Subscription />
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(OurMission);
